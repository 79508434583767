<template>
  <div class="app">
    <div v-if="loadingApp" class="app__center-info center">
      <base-spinner :size="50" color="#009D3E" />
    </div>
    <div v-else-if="infoError" class="app__center-info center error-color">
      Ошибка загрузки
    </div>
    <div v-else>
      <div class="app__topBlock">
        <HeaderComponent v-if="!$route.meta.withoutNav" />
        <NotFound v-if="useAppStore().isPageNotFound" />
        <router-view v-else />
      </div>
      <base-up-button />
      <FooterComponent v-if="!$route.meta.withoutNav" />

      <AuthModal
        v-if="useAppStore().showAuthModal || useRoute()?.name === 'passwordRestore'"
      />

      <AlertModal />
      <Messenger is-mini />
    </div>
    <base-overlay :value="useAppStore().overlay" class="main__z-index">
      <base-spinner :size="50" />
    </base-overlay>
  </div>
</template>

<script lang="ts" setup>
import { useAppStore } from '@/store/modules/app';
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { useRoute, useRouter } from 'vue-router';
import {
  AlertModal,
  AuthModal,
  FooterComponent,
  HeaderComponent,
  Messenger,
} from '@/components';
import { useCompareStore } from '@/store/modules/compare';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { ClearStateAndStorage } from '@/mixins/Common/ClearStateAndStorage';
import { NotFound } from '@/views';

const onResize = () => {
  useAppStore().writeWindowWidth(window.innerWidth);
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

window.addEventListener('resize', onResize);
window.addEventListener('storage', async function (event) {
  if (event.key == 'auth-event') {
    ClearStateAndStorage();
    location.reload();
  }
});
onResize();

document.addEventListener(
  'DOMContentLoaded',
  function () {
    function hasTouchDevice() {
      return !!('ontouchstart' in window || navigator.maxTouchPoints);
    }

    if (!hasTouchDevice()) {
      document.body.classList.add('no-touch');
    } else {
      document.body.classList.add('touch');
    }
  },
  { passive: true },
);

const loadingApp = ref(true);
const infoError = ref(false);
const router = useRouter();

onBeforeMount(() => {
  const baseUrl = window.location.origin;

  useAppStore().changeBaseUrl(baseUrl);
});

onMounted(async () => {
  try {
    loadingApp.value = true;
    await useAppStore().getInfo();
    await useUserStore().getUserInfo();
    if (!useUserStore().user) {
      useCompareStore().getCompareFromLocalStorage();
    }
    useOfferCardsStore().getViewedAdsFromLocalStorage();
  } catch (error) {
    infoError.value = true;
  } finally {
    loadingApp.value = false;
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style lang="scss" scoped>
.app {
  &__center-info {
    width: 100vw;
    height: 100vh;
  }

  &__topBlock {
    min-height: 100vh;
  }
}
</style>
