<template>
  <div v-if="loading" class="container center">
    <base-spinner :size="50" color="#009D3E" />
  </div>
  <div v-else :class="{ fromProfile }" class="container cardsBlock classification">
    <div class="mb--20 none-Mobile">
      <InfoTopBar
        text="В данном разделе вы можете ознакомиться с основной информацией по жилому комплексу. Сервис предоставляет информацию на основе сбора, систематизации и анализа сведений."
      />
    </div>
    <SecondHeader
      v-if="classifier"
      ref="classifierTopBlock"
      v-model="showClassifications"
      :is-sticky="useAppStore().getWindowWidth() > 600"
      :logo="classifier.logo"
      :offer-card-id="classifier?.isResidentialComplex ? undefined : classifier.id"
      :phone="classifier?.phone"
      :title="classifier?.name"
    >
      <template #data>
        <base-progress-bar
          v-if="isVisible"
          :max-rate="classifier?.classifier?.maxRate"
          :rating="classifier?.classifier.calculationResult"
          class="classification__seller-first-rating"
        />
      </template>
      <template #btn>
        <base-button
          v-if="classifier"
          :href="
            classifier?.isResidentialComplex
              ? { name: 'residentialComplex', query: { id: classifier?.id } }
              : { name: 'offerCard', params: { id: classifier?.id } }
          "
          :text="classifier?.isResidentialComplex ? 'На страницу ЖК' : 'К объявлению'"
          all-block-width
          class="no-wrap none-Mobile"
          style-type="coloredBorder"
        />
        <base-button
          v-if="classifier"
          :href="{ name: 'offerCard', params: { id: classifier?.id } }"
          class="only-mobile"
          style-type="greenBorder"
        >
          <template #text>
            <span class="xs-font-size">К объявлению</span>
          </template>
        </base-button>
      </template>
    </SecondHeader>
    <SecondHeader
      v-if="classifier && !isVisible && useAppStore().getWindowWidth() <= 600"
      v-model="showClassifications"
      :logo="classifier.logo"
      :offer-card-id="classifier?.isResidentialComplex ? undefined : classifier.id"
      :phone="classifier.phone"
      :title="classifier.name"
    >
      <template #title>
        <div class="classification__topBar">
          <div>
            {{ classifier.name }}
          </div>
          <base-rating-number
            :max-rate="classifier?.classifier?.maxRate"
            :rating="classifier.classifier.calculationResult"
          />
        </div>
      </template>
      <template #btn>
        <base-button
          v-if="classifier"
          :href="
            classifier?.isResidentialComplex
              ? { name: 'residentialComplex', query: { id: classifier?.id } }
              : { name: 'offerCard', params: { id: classifier?.id } }
          "
          :text="classifier?.isResidentialComplex ? 'На страницу ЖК' : 'К объявлению'"
          all-block-width
          class="no-wrap none-Mobile"
          style-type="coloredBorder"
        />
        <base-button
          v-if="classifier"
          :href="{ name: 'offerCard', params: { id: classifier?.id } }"
          class="only-mobile"
          style-type="greenBorder"
        >
          <template #text>
            <span class="xs-font-size">К объявлению</span>
          </template>
        </base-button>
      </template>
    </SecondHeader>
    <div v-if="showClassifications" class="mb--20">
      <base-expansion-panels
        v-for="(item, i) in classifier?.classifier?.categories"
        :key="i"
        :is-open="true"
        class="classification__expansion-panels"
        isMobileCollapse
        without-mobile-shadow
      >
        <template #title="titleProps">
          <div class="classification__item-title">
            <div>
              {{ item.title }}
            </div>
            <base-divider class="classification__item-title-divider" />
            <div class="classification__item-title-right">
              <div class="classification__item-title-info">
                <div>
                  Индекс ЖК:
                  <span class="main-color">{{ categoryWeight(item.values) }}</span>
                </div>
                <div>|</div>
                <div class="items-in-line gap--5">
                  <span class="none-Mobile">Максимальное значение:</span>
                  <span class="only-mobile">Макс. значение:</span>
                  <span class="main-color">{{ item.weight }}</span>
                </div>
              </div>
              <button
                class="classification__item-title-btn"
                @click="titleProps.events.handleClick()"
              >
                {{ titleProps.data ? 'Свернуть' : 'Развернуть' }}
              </button>
            </div>
          </div>
        </template>
        <template #content="contentProps">
          <div class="classification__table">
            <base-table
              v-if="item.values?.length"
              :headers="getHeaders(item.weight)"
              :items="item.values"
            />
            <div v-else class="second-color">Нет данных</div>
          </div>
          <div class="classification__item-content-btn-box">
            <button
              class="classification__item-content-btn"
              @click="contentProps.events.handleClick()"
            >
              {{ contentProps.data ? 'Свернуть' : 'Развернуть' }}
            </button>
          </div>
        </template>
      </base-expansion-panels>
    </div>
    <div class="mt--20 only-mobile">
      <InfoTopBar
        text="В данном разделе вы можете ознакомиться с основной информацией по жилому комплексу. Сервис предоставляет информацию на основе сбора, систематизации и анализа сведений."
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { HeaderType, ItemType } from '@/types/TableTypes';
import { InfoTopBar, SecondHeader } from '@/components';
import { useClassificationStore } from '@/store/modules/classification';
import { ClassifierType } from '@/types/ClassifierTypes';
import { useAppStore } from '@/store/modules/app';
import { useElementVisibility } from '@vueuse/core';

const props = defineProps<{
  fromProfile?: boolean;
}>();
const emit = defineEmits(['handleClick']);

const classifierTopBlock = ref<HTMLInputElement>();
const isVisible = useElementVisibility(classifierTopBlock);

const getHeaders = (weight: number): HeaderType[] => {
  return [
    {
      text: 'Влияние',
      value: 'influence',
      align: 'center',
    },
    { text: 'Вес', value: 'weight', align: 'center' },
    {
      text: 'Вес ЖК',
      value: 'complexWeight',
      backGroundColumn: '#EAFAD6',
      align: 'center',
    },
    { text: 'Подкатегории', value: 'subcategories' },
    {
      text: `${Math.round(weight * 2) / 10}`,
      value: '1',
      color: 'white',
      backGroundColor: '#0097CB',
      align: 'center',
    },
    {
      text: `${Math.round(weight * 4) / 10}`,
      value: '2',
      color: 'white',
      backGroundColor: '#009D3E',
      align: 'center',
    },
    {
      text: `${Math.round(weight * 6) / 10}`,
      value: '3',
      backGroundColor: '#EEE503',
      align: 'center',
    },
    {
      text: `${Math.round(weight * 8) / 10}`,
      value: '4',
      color: 'white',
      backGroundColor: '#E99A01',
      align: 'center',
    },
    {
      text: `${Math.round(weight * 10) / 10}`,
      value: '5',
      color: 'white',
      backGroundColor: '#C16800',
      align: 'center',
    },
  ];
};
const showClassifications = ref(true);
const loading = ref(false);
const classifier: Ref<undefined | ClassifierType> = ref(undefined);
const handleClick = () => {
  emit('handleClick');
};
const categoryWeight = (values: ItemType[]): number => {
  return +values
    .map(el => el.complexWeight.value)
    .filter(el => typeof el === 'number')
    .reduce(function (a, b) {
      return Number(a) + Number(b);
    }, 0);
};
const router = useRouter();

onMounted(async () => {
  const cardId = useRoute().query.id;

  loading.value = true;

  if (cardId) {
    try {
      const res = await useClassificationStore().searchClassifierById(+cardId);

      if (res) {
        classifier.value = res;
      } else {
        await router.push({ name: 'main' });
      }
    } finally {
      loading.value = false;
    }
  } else {
    await router.push({ name: 'main' });
  }
});
</script>

<style lang="scss" scoped src="./ClassificationComponent.scss"></style>
