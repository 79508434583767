<template>
  <div class="active">
    <div class="active__actions mb--20">
      <div class="active__actions-sort">
        <base-select-input
          :loading="isLoading"
          :modelValue="activeSort"
          :options="sortOptions"
          label="Сортировать по"
          @update:modelValue="activeSort = $event"
        />
      </div>
      <button
        v-if="type === 'active'"
        class="gradient-color"
        @click="showCreateCategoryModal({ categoryTitle: 'Объявление' })"
      >
        Создать объявление
      </button>
    </div>

    <div v-if="isLoading" class="active__cards">
      <base-skeleton-loader
        v-for="i in 4"
        :key="i"
        :styles="{ height: '126px', width: '100%' }"
        class="mb--5"
        type="mediumText"
      />
    </div>
    <div
      v-else-if="preparedResultCards && preparedResultCards.length"
      class="active__cards"
    >
      <div v-for="card in preparedResultCards" :key="card.id">
        <AnnouncementCard :card="card" :type="type" @deleteCategory="deleteCategory" />
      </div>
    </div>
    <div v-else class="center">Объявлений нет</div>
    <CreateDeveloperModal
      :create-category-modal-data="createCategoryModalData"
      isOfferCard
      @close="closeCreateCategoryModal"
      @create-category="createCategory($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import AnnouncementCard from '@/components/ProfileComponents/MyAnnouncements/AnnouncementCard/AnnouncementCard.vue';
import { computed, onMounted, Ref, ref, watch } from 'vue';
import { useDevelopersStore } from '@/store/modules/developers';
import { SmallOfferCardType } from '@/types/MainPageTypes';
import { CreateCategoryModalType, TypesMyAdsType } from '@/types';
import { CreateDeveloperModal } from '@/components/ProfileComponents';
import { useRoute, useRouter } from 'vue-router';

type OptionType = {
  id: number;
  name: string;
  key: string;
};
const props = withDefaults(
  defineProps<{
    type: TypesMyAdsType;
  }>(),
  {},
);

const route = useRoute();
const router = useRouter();

const activeSort: Ref<number | undefined> = ref(undefined);
const sortOptions: OptionType[] = [
  { id: 1, name: 'По цене (сначала дешевле)', key: 'price' },
  { id: 2, name: 'По цене (сначала дороже)', key: 'price' },
  { id: 3, name: 'По дате (сначала старые)', key: 'activeFrom' },
  { id: 4, name: 'По дате (сначала новые)', key: 'activeFrom' },
];

const resultCards = computed((): SmallOfferCardType[] | undefined => {
  if (props.type === 'active') {
    return useDevelopersStore().orderList;
  } else if (props.type === 'drafts') {
    return useDevelopersStore().draftAdList;
  } else if (props.type === 'archive') {
    return useDevelopersStore().archiveAdList;
  }

  return [];
});

const preparedResultCards = computed((): SmallOfferCardType[] => {
  const getValue = (card: SmallOfferCardType, option: OptionType) => {
    if (option.id === 3 || option.id === 4) {
      return new Date(card[option.key]);
    }

    return card[option.key];
  };
  const sortCardsByField = (option: OptionType) => {
    if (option.id % 2 === 0) {
      return (a: SmallOfferCardType, b: SmallOfferCardType) =>
        getValue(a, option) < getValue(b, option) ? 1 : -1;
    } else {
      return (a: SmallOfferCardType, b: SmallOfferCardType) =>
        getValue(a, option) > getValue(b, option) ? 1 : -1;
    }
  };

  const option = sortOptions.find(el => el.id === activeSort.value);

  return resultCards.value && option
    ? resultCards.value.toSorted(sortCardsByField(option))
    : resultCards.value;
});

const createCategoryModalData: Ref<CreateCategoryModalType> = ref({
  show: false,
  categoryTitle: undefined,
});
const showCreateCategoryModal = (event: { categoryTitle: string }) => {
  router.push({ hash: '#create' });
  createCategoryModalData.value = {
    show: true,
    categoryTitle: event.categoryTitle,
  };
};
const closeCreateCategoryModal = () => {
  router.push({ hash: '' });
  createCategoryModalData.value = {
    show: false,
    categoryTitle: undefined,
  };
};

const createCategory = (event: { id: number; title: string }) => {
  useDevelopersStore().createAd(event.id);
  router.push({ query: { menuItem: 'drafts' } });
};

const deleteCategory = async (id: number) => {
  useDevelopersStore().deleteAd(props.type, id);
};

const isLoading = ref(false);
const getAnnouncements = async () => {
  try {
    isLoading.value = true;

    if (!resultCards.value) {
      if (props.type === 'active') {
        await useDevelopersStore().getOrderList();
      } else if (props.type === 'drafts') {
        await useDevelopersStore().getDrafts();
      } else if (props.type === 'archive') {
        await useDevelopersStore().getArchive();
      }

      activeSort.value = 4;
    }
  } finally {
    isLoading.value = false;
  }
};

const hash = computed(() => {
  return route?.hash;
});

const checkIsCreate = () => {
  if (hash.value) {
    const isCreate = hash.value === '#create';

    if (isCreate) {
      showCreateCategoryModal({ categoryTitle: 'Объявление' });
    }
  }
};

onMounted(async () => {
  checkIsCreate();
  await getAnnouncements();
});
watch(
  () => hash.value,
  () => {
    checkIsCreate();
  },
);
watch(
  () => props.type,
  () => {
    getAnnouncements();
  },
);
</script>

<style lang="scss" scoped src="./AnnouncementList.scss"></style>
