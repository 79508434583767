<template>
  <div class="map-block">
    <yandex-map
      v-model="map"
      :height="isFull ? '100vh' : `${height === '100%' ? '100%' : `${height}px`}`"
      :settings="{
        location: {
          center: prepareCoordinates(
            centerPointMap ? centerPointMap : [47.22208, 39.720353],
          ),
          zoom: zoom ? zoom : centerPointMap ? 12 : 9,
        },
      }"
      cursor-grab
      width="100%"
    >
      <yandex-map-default-scheme-layer />
      <yandex-map-default-features-layer />
      <yandex-map-controls :settings="{ position: 'right' }">
        <yandex-map-zoom-control />
      </yandex-map-controls>
      <yandex-map-controls :settings="{ position: 'left' }">
        <yandex-map-geolocation-control />
      </yandex-map-controls>
      <div v-for="item in resultCardsCoordinates" :key="item.id">
        <yandex-map-marker
          :settings="{ coordinates: prepareCoordinates(item.coordinates) }"
          position="top-center left-center"
        >
          <div
            :class="{ active: activeCard?.id === item.id }"
            class="pin"
            @click="showCard(item.id)"
          >
            <base-image
              :src="require('@/assets/img/banner/icon-for-map.svg')"
              contain
              name="map"
            />
          </div>
        </yandex-map-marker>
      </div>
    </yandex-map>
    <div v-if="isLoadingCardActive" class="map-block__card">
      <LoadingOfferCard />
    </div>
    <div v-else-if="activeCard && showOfferCard" class="map-block__card">
      <OfferSmallCard
        :card="activeCard"
        withClose
        @close="
          () => {
            activeCard = undefined;
          }
        "
        @change-active="goToCard()"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LoadingOfferCard, OfferSmallCard } from '@/components/Cards';
import { Ref, ref, shallowRef } from 'vue';
import type { LngLat, YMap } from '@yandex/ymaps3-types';
import {
  YandexMap,
  YandexMapControls,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapGeolocationControl,
  YandexMapMarker,
  YandexMapZoomControl,
} from 'vue-yandex-maps';
import { ResultCardType } from '@/types';
import { OfferCardCoordinatesType } from '@/api/house/types';
import { useOfferCardsStore } from '@/store/modules/offerCards';
import { useRouter } from 'vue-router';

const props = withDefaults(
  defineProps<{
    resultCardsCoordinates?: OfferCardCoordinatesType[];
    height?: string;
    centerPointMap?: LngLat;
    zoom?: number;
    showOfferCard?: boolean;
    isFull?: boolean;
  }>(),
  {
    height: '500',
  },
);

const isLoadingCardActive = ref(false);
const map = shallowRef<null | YMap>(null);

const prepareCoordinates = (coordinates: LngLat) => {
  return [coordinates[1], coordinates[0]];
};
const activeCard: Ref<ResultCardType | undefined> = ref(undefined);
const showCard = async (cardId: number) => {
  if (props.showOfferCard) {
    isLoadingCardActive.value = true;
    try {
      const res = await useOfferCardsStore().searchOfferCardById(+cardId);

      if (res) {
        activeCard.value = res;
      }
    } finally {
      isLoadingCardActive.value = false;
    }
  }
};
const router = useRouter();
const goToCard = () => {
  router.push({ name: 'offerCard', params: { id: activeCard.value?.id } });
};
</script>

<style lang="scss" scoped src="./MapBlock.scss"></style>
