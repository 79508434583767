import { defineStore } from 'pinia';
import { store } from '@/store';
import {
  createCompareApi,
  deleteCompareApi,
  deleteHouseFromCompareApi,
  getComparisonByIdApi,
  getComparisonsApi,
  updateCompareApi,
  updateCompareNameApi,
} from '@/api/compare';
import { CreateCompareDataType, UpdateCompareDataType } from '@/api/compare/types';
import { CompareType, ResultCardType } from '@/types';
import { prepareOfferCard } from '@/mixins/Cards/OfferCard';
import { useUserStore } from '@/store/modules/user';
import { useAppStore } from '@/store/modules/app';

type CompareStoreType = {
  compareList: ResultCardType[];
  compareData: CompareType | undefined;
  compareIds: number[];
};

export const defaultCompareStore: CompareStoreType = {
  compareList: [],
  compareData: undefined,
  compareIds: [],
};
export const useCompareStore = defineStore('compare', {
  state: (): CompareStoreType => {
    return { ...defaultCompareStore };
  },
  actions: {
    async createCompare(data: CreateCompareDataType) {
      const res = await createCompareApi(data);

      if (res) {
        useUserStore().addComparisons();

        return res.data;
      }
    },
    async updateCompareName(data: CompareType) {
      const res = await updateCompareNameApi(data);

      if (res) {
        if (this.compareData) {
          this.compareData = { ...this.compareData, name: data.name };
        }

        return res.data;
      }
    },
    async getComparisons() {
      const res = await getComparisonsApi();

      if (res) {
        return res.data.comparisons;
      }
    },
    async getComparisonById(comparisonId: number) {
      const res = await getComparisonByIdApi(comparisonId);

      if (res) {
        return {
          ...res.data,
          found: res.data.found
            ? res.data.found.map(el => {
                return prepareOfferCard(el);
              })
            : [],
        };
      }
    },
    async deleteHouseFromCompare(compareId: number, cardId: number) {
      const res = await deleteHouseFromCompareApi({
        id: compareId,
        value: cardId,
      });

      if (res) {
        this.changeCompareList(this.compareList.filter(el => el.id !== cardId));

        return res.data;
      }
    },
    async deleteCompare(compareId: number) {
      const res = await deleteCompareApi(compareId);

      if (res) {
        useUserStore().deleteComparisons();

        return res.data;
      }
    },
    async updateCompare(data: UpdateCompareDataType) {
      return await updateCompareApi(data);
    },
    async changeIsCompare(card: ResultCardType) {
      const promise: Promise<'showInfo' | 'success'> = new Promise(resolve => {
        const compareIndex = this.compareIds.findIndex(el => el === card.id);

        if (compareIndex !== undefined && compareIndex >= 0) {
          this.compareIds.splice(compareIndex, 1);
          resolve('success');

          const compareListIndex = this.compareList.findIndex(el => el.id === card.id);

          if (compareListIndex !== undefined && compareListIndex >= 0) {
            this.compareIds.splice(compareListIndex, 1);
          }

          if (this.compareData) {
            this.deleteHouseFromCompare(this.compareData.id, card.id);
          }
        } else {
          useAppStore().sendStatistic(13);
          this.compareIds.push(card.id);
          this.compareList.push(card);
          if (this.compareData) {
            this.updateCompare({
              id: this.compareData.id,
              values: this.compareIds,
            });
          } else if (useUserStore().user) {
            this.createCompare({ values: [card.id] }).then(res => {
              if (res) {
                this.changeCurrentCompare(
                  {
                    id: +res.comparisonHouse,
                    name: 'Новый список',
                  },
                  [card.id],
                );
              }
            });
          } else {
            localStorage.setItem('compare-ids', JSON.stringify(this.compareIds));
            localStorage.setItem('compare-list', JSON.stringify(this.compareList));
          }

          resolve('showInfo');
        }
      });

      return promise;
    },
    getCompareFromLocalStorage() {
      const stringCompareList = localStorage.getItem('compare-list');

      if (stringCompareList) {
        this.changeCompareList(JSON.parse(stringCompareList));
      } else {
        this.changeCompareList([]);
      }
    },
    changeCurrentCompare(compareData: CompareType | undefined, compareIds: number[]) {
      this.compareData = compareData;
      this.compareIds = compareIds;

      localStorage.setItem('compare-list', JSON.stringify([]));
      localStorage.setItem('compare-ids', JSON.stringify(this.compareIds));
    },
    changeCompareList(compareList: ResultCardType[]) {
      this.compareList = compareList;
      this.compareIds = compareList.map(el => el.id);
    },
  },
  getters: {
    getIsCompare:
      state =>
      (cardId: number): boolean => {
        return !!state.compareIds.find(el => el === cardId);
      },
    getCompareCount: state => (): number => {
      return state.compareList.length;
    },
  },
});

export const useClassificationStoreWithOut = () => {
  return useCompareStore(store);
};
