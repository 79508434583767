<template>
  <div v-if="isLoading" class="center container content mt--30">
    <base-spinner :size="50" color="#009D3E" />
  </div>
  <div v-else-if="editableData" class="container content">
    <router-link :to="backLink" class="items-in-line flex-start mt--25 gap--5">
      <base-icon color="#009D3E" name="arrow_back" />
      <span class="underline main-color">Назад</span>
    </router-link>
    <div class="items-in-line flex-start flex-wrap mt--25 mb--30">
      <div
        v-for="(item, i) in Object.entries(editableData.breadcrumbs)"
        :key="item[0]"
        class="items-in-line gap--5"
      >
        <div
          v-if="i !== Object.values(editableData.breadcrumbs).length - 1"
          class="items-in-line gap--14"
        >
          <h3 class="pointer main-color" @click="changeCategory(+item[0])">
            {{ item[1] }}
          </h3>
          <h3 class="second-color">/</h3>
        </div>
        <div v-else>
          <h3 class="second-color">
            {{ item[1] }}
          </h3>
        </div>
      </div>
    </div>
    <div id="editableData" class="mt--20">
      <div v-if="editableData?.param_groups">
        <div v-if="withoutGroupParams.length" class="mb--20">
          <UpdateForm
            v-model:paramsData="paramsData"
            v-model:v$="v$"
            :categoryId="editableData?.category_id"
            :errors="errors"
            :params="withoutGroupParams"
            :parentId="editableData?.id"
            :rules="rules"
            @focus="delete errors[$event]"
            @validation-errors-handler-by-key="validationErrorsHandlerByKey($event)"
            @input-touch="v$['value']?.$touch"
            @change-category="changeCategory($event)"
          />
        </div>
        <UpdateParamsGroups
          v-if="editableData?.param_groups.filter(el => el.id !== 0).length"
          v-model:paramsData="paramsData"
          v-model:v$="v$"
          :errors="errors"
          :paramValues="editableData.param_values"
          :params-groups="editableData.param_groups.filter(el => el.id !== 0)"
          :parentId="editableData?.id"
          :rules="rules"
          class="mb--30"
          @focus="delete errors[$event]"
          @validation-errors-handler-by-key="validationErrorsHandlerByKey($event)"
          @input-touch="v$['value']?.$touch"
          @change-category="changeCategory($event)"
          @create-category="createCategory(...$event)"
          @delete-category="deleteCategory(...$event)"
        />
      </div>
      <UpdateFormFirst
        v-model:paramsData="paramsData"
        v-model:v$="v$"
        :errors="errors"
        :isSaveLoading="isSaveLoading"
        @focus="delete errors[$event]"
        @save="formSubmit"
        @validation-errors-handler-by-key="validationErrorsHandlerByKey($event)"
      />
      <base-divider v-if="nextStepCategories.length" class="mt--30 mb--30" />
      <div v-if="nextStepCategories.length">
        <div v-for="category in nextStepCategories">
          <UpdateCategories
            :categoryId="category.options['list-category']"
            :editable-category="category.value"
            :parentId="editableData?.id"
            :title="category.title"
            @change-category="changeCategory($event)"
            @create-category="createCategory($event, category.id)"
            @delete-category="deleteCategory($event, category.id)"
          />
        </div>
      </div>
    </div>
  </div>
  <base-info-modal
    v-if="savedDataModal.show"
    :is-error="savedDataModal.isError"
    :show-info-modal="savedDataModal.show"
    @close="closeSavedDataModal"
  >
    <div>{{ savedDataModal.message }}</div>
  </base-info-modal>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, ref, Ref, toRaw } from 'vue';
import { useVuelidate, ValidationRuleWithoutParams } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ErrorsType, ParamsDataType } from '@/types';
import {
  errorHandle,
  errorsHandler,
  errorsHandlerByKey,
  validationErrorsByRequest,
} from '@/mixins/Common/Common';
import {
  prepareValueByType,
  ValueType,
  ValueTypeFromBack,
} from '@/mixins/Common/DeveloperAdmin';
import UpdateFormFirst from '@/components/UpdateDeveloperComponents/UpdateForm/UpdateFormFirst.vue';
import { useRoute, useRouter } from 'vue-router';
import { updateCategoryDataApi } from '@/api/developer';
import { useDevelopersStore } from '@/store/modules/developers';
import {
  CategoryDataForUpdateApiType,
  ParamsValuesType,
  UpdateTypes,
} from '@/api/developer/types';
import { UpdateCategories, UpdateForm, UpdateParamsGroups } from '@/components';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';
import { ParamDataForSendType } from '@/types/UpdateDeveloperTypes';
import { useAppStore } from '@/store/modules/app';

type RulesType = { [key: string]: { [key: string]: ValidationRuleWithoutParams } };
const isLoading = ref(false);
const isSaveLoading = ref(false);
const router = useRouter();
const editableData: Ref<CategoryDataForUpdateApiType | undefined> = ref(undefined);

const paramsData: Ref<ParamsDataType> = ref({});

const backLink = computed(() => {
  let paramId;

  if (type.value === 'updateResidentialComplex') {
    paramId = 'MyResidentialComplex';
  } else if (type.value === 'updateDeveloper') {
    paramId = 'myDeveloper';
  } else {
    paramId = 'myAnnouncements';
  }

  return { name: 'profile', params: { id: paramId } };
});

const nextStepCategories = computed(() => {
  if (editableData.value) {
    return editableData.value.param_values
      .filter(el => el.type === 22)
      .filter(el => {
        return (
          el.group_id === 0 ||
          !editableData.value?.param_groups.map(group => group.id).includes(el.group_id)
        );
      });
  } else {
    return [];
  }
});
const withoutGroupParams = computed(() => {
  if (editableData.value && editableData.value.param_groups.filter(el => el.id === 0)) {
    return editableData.value.param_values.filter(
      el => el.group_id === 0 && el.type !== 22,
    );
  } else {
    return [];
  }
});

const rules = computed((): RulesType => {
  let rules: RulesType = {};

  editableData.value?.param_values
    .filter(el => el.type !== 22)
    .forEach(param => {
      let validators = {};

      if (param.required) {
        validators = { required };
      }
      rules[param.id] = { ...validators };
    });

  rules['value'] = { required };
  rules['public'] = {};
  rules['is_missed'] = {};

  return rules;
});

const v$ = useVuelidate<ParamsDataType>(rules, paramsData, {
  $autoDirty: true,
  $stopPropagation: true,
});

const errors: Ref<ErrorsType> = ref({});

const type = computed((): UpdateTypes => {
  return useRoute().name as UpdateTypes;
});
const validationErrorsHandlerByKey = (key: string | number) => {
  let error: string | undefined = errorsHandlerByKey(String(key), rules, v$);

  if (error) {
    errors.value[key] = error;
  } else {
    delete errors.value[key];
  }
};
const validationErrorsHandler = () => {
  errors.value = errorsHandler(paramsData.value, rules, v$);
};
const prepareNumberValue = (value: string | null): string | null => {
  return value ? value.replace(/[^0-9]/g, '') : null;
};

const prepareDataForSend = (param: ValueType): ParamDataForSendType => {
  if ((param.type === 2 || param.type === 5) && param.value) {
    return String(param.value).replace(/\s/g, '');
  }
  if (param.type === 4) {
    if (param.value && +param.value) {
      return [param.value];
    } else {
      return JSON.parse(JSON.stringify([]));
    }
  }
  if (param.type === 8 && param.value) {
    return prepareNumberValue(param.value);
  }
  if ((param.type === 12 || param.type === 20) && param.value) {
    return param.value.split(/\s/, 2)[0];
  }
  if (param.type === 19 && param.value) {
    return `${param.value}-01-01`;
  }

  if (param.type === 24 || param.type === 25 || param.type === 28) {
    return {
      min: prepareNumberValue(param.value.min),
      max: prepareNumberValue(param.value.max),
    };
  }

  return param.value === undefined ? null : param.value;
};

const constructorDataForSend = (): FormData => {
  let sendData = new FormData();

  sendData.append('id', String(editableData.value?.id));
  Object.entries(paramsData.value).forEach(param => {
    if (param[1].type === 'main') {
      const oldValue = editableData.value
        ? editableData.value[param[0] as 'value' | 'public' | 'is_missed']
        : null;

      if (param[1].value !== oldValue) {
        sendData.append(param[0], param[1].value as string | Blob);
      }
    } else {
      const oldParam = editableData.value?.param_values.find(el => el.id === +param[0]);

      if (oldParam) {
        const oldParamValue = JSON.stringify(toRaw(oldParam?.value));
        const newParamValue = prepareDataForSend(param[1]);

        if (
          oldParamValue !== JSON.stringify(newParamValue) ||
          param[1].type === 10 ||
          param[1].type === 11 ||
          param[1].type === 15 ||
          param[1].type === 26
        ) {
          switch (param[1].type) {
            case 3:
              sendData.append(`parameters[${param[0]}]`, param[1].value ? '1' : '0');
              break;
            case 4:
              sendData.append(`parameters[${param[0]}]`, String(newParamValue));
              break;
            case 14:
              if (newParamValue) {
                (newParamValue as number[]).forEach(el => {
                  sendData.append(`parameters[${param[0]}][]`, String(el));
                });
              }
              break;
            case 15:
            case 10:
              if (param[1].value.newValue !== null) {
                sendData.append(
                  `parameters[${param[0]}]`,
                  param[1].value.newValue[0] as string | Blob,
                );
              }
              break;

            case 11:
            case 26:
              if (param[1].value.newValue !== null) {
                Object.values(param[1].value.newValue).forEach(val => {
                  if (val !== null) {
                    sendData.append(`parameters[${param[0]}][]`, val);
                  }
                });
              }
              break;
            case 24:
            case 25:
            case 28:
              sendData.append(
                `parameters[${param[0]}][min]`,
                String(prepareNumberValue(param[1].value.min)),
              );
              sendData.append(
                `parameters[${param[0]}][max]`,
                String(prepareNumberValue(param[1].value.max)),
              );
              break;
            case 19:
              sendData.append(`parameters[${param[0]}]`, String(param[1].value));
              break;
            default:
              sendData.append(`parameters[${param[0]}]`, String(newParamValue));
              break;
          }
        }
      }
    }
  });

  return sendData;
};

const savedDataModal = ref({
  show: false,
  isError: false,
  message: '',
});

const closeSavedDataModal = () => {
  savedDataModal.value = {
    show: false,
    isError: false,
    message: '',
  };
};
const formSubmit = async () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
    validationErrorsHandler();

    return;
  }
  if (editableData.value) {
    const sendData = constructorDataForSend();

    isSaveLoading.value = true;
    try {
      const res = await updateCategoryDataApi(type.value, sendData);

      if (res) {
        savedDataModal.value = {
          show: true,
          isError: false,
          message: 'Данные успешно изменены',
        };
        errors.value = {};
      }
    } catch (error) {
      errors.value = validationErrorsByRequest(error, 'parameters');
      savedDataModal.value = {
        show: true,
        isError: true,
        message: Object.keys(errors.value).length
          ? 'Данные заполнены некорректно'
          : 'Произошла ошибка',
      };
    } finally {
      isSaveLoading.value = false;
    }
  }
};

const updateCategory = async (categoryId: number) => {
  isLoading.value = true;

  try {
    const res = await useDevelopersStore().getCategoryDataForUpdate(
      type.value,
      categoryId,
    );

    if (res) {
      editableData.value = res;
      Object.keys(paramsData.value).forEach(key => delete paramsData.value[key]);
      paramsData.value['value'] = { type: 'main', value: res.value };
      paramsData.value['public'] = { type: 'main', value: res.public };
      if (res.may_be_missed) {
        paramsData.value['is_missed'] = { type: 'main', value: res.is_missed };
      }

      res.param_values.forEach((param: ParamsValuesType) => {
        if (param.type !== 22) {
          paramsData.value[param.id] = prepareValueByType({
            type: param.type,
            value: param.value,
          } as ValueTypeFromBack);
        }
      });
    }
  } catch (error) {
    errorHandle(error);
    await router.push({ name: 'main' });
  } finally {
    isLoading.value = false;
  }
};

const smoothScroll = inject('smoothScroll') as (args: SmoothScrollOptions) => void;
const changeCategory = (categoryId: number) => {
  updateCategory(categoryId);
  router.push({
    query: {
      id: categoryId,
    },
  });
  const myEl = document.getElementById(`editableData`);

  if (myEl) {
    smoothScroll({
      scrollTo: myEl,
      offset: -200,
      updateHistory: false,
    });
  }
};
const createCategory = (
  newCategory: { id: number; title: string },
  categoryId: number,
) => {
  if (editableData.value?.param_values) {
    editableData.value.param_values = editableData.value?.param_values.map(param => {
      if (param.type === 22 && param.value) {
        if (param.id === categoryId) {
          return { ...param, value: [...param.value, newCategory] };
        }
      }

      return param;
    });
  }
};

const deleteCategory = (paramId: number, categoryId: number) => {
  if (editableData.value?.param_values) {
    editableData.value.param_values = editableData.value?.param_values.map(param => {
      if (param.type === 22 && param.value) {
        if (param.id === categoryId) {
          return { ...param, value: param.value.filter(el => el.id !== paramId) };
        }
      }

      return param;
    });
  }
};

onMounted(async () => {
  const categoryId = useRoute().query.id;

  if (categoryId) {
    await updateCategory(+categoryId);
  } else {
    useAppStore().togglePageNotFound(true);
  }
});
</script>

<style lang="scss" scoped></style>
