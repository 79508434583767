<template>
  <div class="container content profile">
    <div class="profile__content">
      <div class="profile__content-left-block">
        <div v-if="userInfo" class="profile__content-info">
          <div>
            <base-image
              :key="userInfo.logo"
              :contain="!userInfo.logo"
              :cover="!!userInfo.logo"
              :src="userInfo.logo || require('@/assets/img/profile/avatar.png')"
              class="profile__content-avatar"
              height="80"
              name="ava"
              width="80"
            />
          </div>
          <div class="medium mt--10 mb--10">
            {{ userInfo.name || 'Имя пользователя' }}
          </div>
        </div>
        <base-divider class="mt--10 mb--10 profile__content-divider" />
        <ProfileMenu
          :activeMenuItem="activeMenuItem"
          :profileMenuItems="profileMenuItems"
          class="profile__content-menu"
          @changeActiveMenuItem="activeMenuItem = $event"
        />
        <div class="profile__content-mobile-menu">
          <base-menu>
            <template #menuItems="menuItemsProps">
              <ProfileMenu
                :activeMenuItem="activeMenuItem"
                :profileMenuItems="profileMenuItems"
                @changeActiveMenuItem="
                  menuItemsProps.events.handleClick();
                  activeMenuItem = $event;
                "
              />
            </template>
          </base-menu>
        </div>
      </div>
      <div v-if="activeMenuItem">
        <div class="items-in-line flex-start mt--0">
          <h2>{{ activeMenuItem.title }}</h2>
          <div id="title"></div>
        </div>
        <div>
          <component
            :is="activeMenuItem.component"
            :key="activeMenuItem.id"
            v-bind="currentProperties"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  Analytics,
  CompareList,
  FavoriteAds,
  MyAnnouncements,
  MyDeveloper,
  MyResidentialComplex,
  Notifications,
  PartnerProgram,
  Profile,
  ProfileMenu,
  Services,
  Support,
} from '@/components/ProfileComponents';
import { Messenger } from '@/components';
import { computed, markRaw, onMounted, ref, Ref, watch } from 'vue';
import { ProfileMenuItemsType, ProfileMenuItemType } from '@/types';
import { useRoute } from 'vue-router';
import { UserType, useUserStore } from '@/store/modules/user';
import { useChatStore } from '@/store/modules/chat';

const userInfo = computed((): UserType | undefined => {
  return useUserStore().user;
});
const chatCount = computed((): number => {
  return useChatStore().chatIdsWithNewMessage.length;
});

const profileMenuItems = computed((): ProfileMenuItemsType => {
  return [
    [
      {
        id: 'profile',
        title: 'Настройка профиля',
        component: markRaw(Profile),
      },
    ],
    [
      {
        id: 'chat',
        title: 'Чаты',
        count: chatCount.value,
        component: markRaw(Messenger),
      },
      {
        id: 'notifications',
        title: 'Уведомления',
        count: userInfo.value?.notificationUser.open,
        component: markRaw(Notifications),
      },
      {
        id: 'favorite',
        title: 'Избранное',
        count: useUserStore().user?.favorite_ids.length,
        component: markRaw(FavoriteAds),
      },
      {
        id: 'compare',
        title: 'Списки сравнений',
        count: useUserStore().user?.comparisons,
        component: markRaw(CompareList),
      },
    ],
    [
      {
        id: 'myDeveloper',
        title: 'Мои объекты',
        component: markRaw(MyDeveloper),
        hide: !useUserStore().user?.is_construction_company,
      },
      {
        id: 'MyResidentialComplex',
        title: 'Мой ЖК',
        component: markRaw(MyResidentialComplex),
        hide: !useUserStore().user?.is_construction_company,
      },
      {
        id: 'myAnnouncements',
        title: 'Мои объявления',
        component: markRaw(MyAnnouncements),
      },
      {
        id: 'partnerProgram',
        title: 'Партнерская программа',
        component: markRaw(PartnerProgram),
        hide: !useUserStore().user?.access.includes('PartnerProgramEdit'),
      },
    ],
    [
      {
        id: 'analytics',
        title: 'Аналитика',
        component: markRaw(Analytics),
      },
      {
        id: 'services',
        title: 'Сервисы',
        component: markRaw(Services),
      },
    ],
    [
      {
        id: 'support',
        title: 'Поддержка',
        component: markRaw(Support),
      },
    ],
  ];
});
let activeMenuItem: Ref<ProfileMenuItemType | undefined> = ref(undefined);

const currentProperties = computed(() => {
  if (activeMenuItem.value?.id === 'classificationResults') {
    return {
      fromProfile: true,
    };
  }

  return {};
});

const route = useRoute();
const routerParamId = computed(() => {
  return route.params.id;
});

const updateMenuItem = () => {
  let newMenuItem;

  if (routerParamId.value) {
    profileMenuItems.value.forEach(category => {
      const foundMenuItem = category.find(el => el.id === routerParamId.value);

      if (foundMenuItem && !foundMenuItem.hide) {
        newMenuItem = foundMenuItem;
      }
    });
  }

  activeMenuItem.value = newMenuItem ?? profileMenuItems.value[0][0];
};

onMounted(async () => {
  updateMenuItem();
});

watch(
  () => routerParamId.value,
  () => {
    updateMenuItem();
  },
);
watch(
  () => useUserStore().user!.notificationUser.open,
  (newValue, oldValue) => {
    if (newValue > oldValue) {
      useUserStore().getNotifications();
    }
  },
);
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
