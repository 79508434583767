<template>
  <component
    :is="useAppStore().getWindowWidth() <= 600 ? 'base-card' : 'div'"
    class="ads-items"
    full-width-mobile
  >
    <div v-if="allMessages.length || isShowAllMessagesLoading" class="ads-items__section">
      <div class="ads-items__inputs">
        <base-select-input
          :loading="isShowAllMessagesLoading"
          :modelValue="sortOptionsId"
          :options="sortOptions"
          arrow="expand_more"
          height="34"
          label="Сортировка"
          @update:model-value="sortChats"
        />
      </div>
    </div>
    <div v-if="isShowAllMessagesLoading">
      <base-skeleton-loader
        v-for="i in 1"
        :key="i"
        :styles="{ height: '94px', width: '100%' }"
        class="mb--5 mt--20"
        type="mediumText"
      />
    </div>

    <div v-else-if="prepareMessages.length">
      <div v-for="message in prepareMessages" :key="message.id" class="mt--20">
        <base-expansion-panels
          :is-open="currentChatId === message.id"
          is-full-collapse
          @openBlock="showMessages(message.id)"
          @close-block="closeChat(message.id)"
        >
          <template #title="titleProps">
            <div class="ads-items__section">
              <div class="ads-items__container">
                <base-image
                  :src="message.type === 10 ? message.more.house.images[0] : undefined"
                  border-radius
                  cover
                  height="60"
                  name="img"
                  width="96"
                />
                <div class="ads-items__container-info">
                  <div v-if="message.type === 10">
                    <div v-if="message.type === 10">
                      <span class="medium">Адрес:</span> {{ message.more.house.address }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      message.type === 11 && message.more.id !== useUserStore().user?.id
                    "
                  >
                    <div>
                      <span class="medium">Системный чат с:</span> {{ message.more.name }}
                    </div>
                    <div><span class="medium">Id:</span> {{ message.more.id }}</div>
                  </div>
                  <div v-else>
                    <span class="medium">Чат с администрацией</span>
                  </div>
                  <div>
                    <div v-if="message.type === 10">
                      <span class="medium">Цена:</span
                      >{{ (+message.more.house.price).toLocaleString() }} ₽
                    </div>
                    <div v-if="message.type === 10">
                      <span class="medium">id:</span> {{ message.more.house.id }}
                    </div>
                  </div>
                  <div class="ads-items__message-date">
                    {{ new Date(message.last_message).toLocaleString() }}
                  </div>
                </div>
                <div class="ads-items__container-top-right">
                  <div class="items-in-column align-center">
                    <base-popup v-if="message.removable" with-padding>
                      <button class="ellipsis">...</button>
                      <template #content>
                        <div class="items-in-column gap--0">
                          <button
                            :disabled="deleteChatIdsLoading.find(el => el === message.id)"
                            @click="deleteChat(message.id)"
                          >
                            <base-spinner
                              v-if="deleteChatIdsLoading.find(el => el === message.id)"
                              :size="20"
                              color="#009D3E"
                            />
                            <span v-else>Удалить</span>
                          </button>
                        </div>
                      </template>
                    </base-popup>
                    <div v-if="!message.it_was_read" class="ads-items__container-circle">
                      {{ message.unread_messages }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <base-divider class="ads-items__without-padding" />
            <div v-if="loadingChatIds.includes(message.id)" class="center mt--30">
              <base-spinner :size="50" color="#009D3E" />
            </div>
            <div v-else>
              <ChatComponent
                v-if="chatInfo[message.id]?.messages"
                :messages="chatInfo[message.id]?.messages"
                class="ads-items__item ads-items__chat"
              />
              <div v-if="formData" class="mb--0 ads-items__send-message ads-items__item">
                <base-textarea
                  v-model="formData.messages[message.id]"
                  :styleType="'light'"
                  height="100"
                  label="Сообщение"
                  withScroll
                />
                <button
                  :disabled="sendMessageChatsLoading.includes(message.id)"
                  @click="sendMessage(message.id)"
                >
                  <svg
                    class="ads-items__send-message-triangle-box"
                    height="24"
                    viewBox="-50 -50 300 300"
                    width="24"
                  >
                    <polygon
                      class="ads-items__send-message-triangle"
                      points="100,0 0,200 200,200"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </template>
        </base-expansion-panels>
      </div>
    </div>
    <div v-else class="mt--20 pt--20 pb--20">
      <div class="center allBlockWidth">Сообщений нет</div>
    </div>

    <base-pagination-group
      v-if="allChats.length || isShowAllMessagesLoading"
      :key="paginationKey"
      v-model="prepareMessages"
      :class="{ 'mt--30': useAppStore().getWindowWidth() > 600 }"
      :is-loading="isShowAllMessagesLoading"
      :show-btn="false"
      :values="allChats"
      auto-add-mobile
    />
  </component>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';
import { ChatComponent } from '@/components';
import { messagesFromChanelTypeFromBack } from '@/api/chat/types';
import { FormDataType } from '@/types';
import { useChatStore } from '@/store/modules/chat';
import { useUserStore } from '@/store/modules/user';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@/store/modules/app';

type OptionType = {
  id: number;
  name: string;
};
const prepareMessages: Ref<messagesFromChanelTypeFromBack[]> = ref([]);
const allChats: Ref<messagesFromChanelTypeFromBack[]> = ref([]);
const paginationKey = ref(0);
const router = useRouter();
const props = withDefaults(
  defineProps<{
    isShowAllMessagesLoading: boolean;
    sendMessageChatsLoading: number[];
    allMessages: messagesFromChanelTypeFromBack[];
    loadingChatIds: number[];
    chatInfo: { [key: string]: messagesFromChanelTypeFromBack };
  }>(),
  {},
);

const emit = defineEmits([
  'showAllChats',
  'getMessagesByChannelId',
  'sendMessage',
  'deleteChatById',
]);

// eslint-disable-next-line no-undef
const formData = defineModel<FormDataType>();

const sortOptions: OptionType[] = [
  { id: 1, name: 'Все сообщения' },
  { id: 2, name: 'Непрочитанные' },
  { id: 3, name: 'Прочитанные' },
];

const sortOptionsId: Ref<1 | 2 | 3> = ref(1);
const currentChatId: Ref<number | undefined> = ref(undefined);
const deleteChatIdsLoading: Ref<number[]> = ref([]);
const chatIdsWithNewMessage = computed((): number[] => {
  return useChatStore().chatIdsWithNewMessage;
});
const sortChats = (optionId: 1 | 2 | 3) => {
  sortOptionsId.value = optionId;
  switch (optionId) {
    case 1:
      allChats.value = props.allMessages;
      break;
    case 2:
      allChats.value = props.allMessages.filter(el => !el.it_was_read);
      break;
    case 3:
      allChats.value = props.allMessages.filter(el => el.it_was_read);
      break;
  }
  prepareMessages.value = allChats.value;

  paginationKey.value++;
};

const updateChats = () => {
  if (allChats.value.length) {
    allChats.value = allChats.value.map(chat => {
      const foundChat = props.allMessages.find(el => el.id === chat.id);

      return foundChat ? foundChat : chat;
    });
  } else {
    sortChats(sortOptionsId.value);
  }
};

const showMessages = async (id: number) => {
  useChatStore().addOpenChatId(id);
  await router.push({
    query: {
      id: id,
    },
  });
  emit('getMessagesByChannelId', id);
};
const closeChat = (id: number) => {
  useChatStore().deleteOpenChatId(id);
};
const sendMessage = async (chatId: number) => {
  emit('sendMessage', chatId);
};

const deleteChat = async (chatId: number) => {
  deleteChatIdsLoading.value.push(chatId);
  try {
    await useChatStore().deleteChatById(chatId);
    emit('deleteChatById', chatId);
  } finally {
    const index = deleteChatIdsLoading.value.findIndex(el => el === chatId);

    if (index >= 0) {
      deleteChatIdsLoading.value.splice(index, 1);
    }
    sortChats(sortOptionsId.value);
  }
};

onMounted(() => {
  emit('showAllChats');

  const chatId = useRoute().query.id;

  if (chatId) {
    useChatStore().addOpenChatId(+chatId);
    emit('getMessagesByChannelId', chatId);
    currentChatId.value = +chatId;
  }
});

watch(
  () => props.allMessages,
  () => {
    updateChats();
  },
  { deep: true },
);

onUnmounted(() => {
  useChatStore().clearOpenChatId();
});
</script>

<style lang="scss" scoped src="./AdsItems.scss"></style>
