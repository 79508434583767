<template>
  <div v-if="isLoading" class="container center">
    <base-spinner :size="50" color="#009D3E" />
  </div>
  <div v-else>
    <base-card v-if="prepareValues.length" class="wallet" fullWidthMobile>
      <div class="wallet__title">Список хранится 180 дней</div>
      <div class="wallet__table none-display-md">
        <base-table
          :emits="['showCompare']"
          :headers="headers"
          :items="prepareValues"
          :style="'simple'"
          @showCompare="showCompare"
        />
      </div>
      <div class="display-md">
        <div
          v-for="value in prepareValues"
          class="wallet__mobile-table-row mobile-table__row gap--10"
        >
          <div v-for="header in headers" :style="{ gridArea: header.value }">
            <div v-if="!value[header.value].icon" class="items-in-line gap--5">
              <span class="medium">{{ header.text }}:</span>
              {{ value[header.value].value }}
            </div>
            <div v-else>
              <base-icon
                :name="value[header.value]?.icon"
                clickable
                color="#94857d"
                height="18"
                width="21"
                @click="header.click ? header.click(value) : () => {}"
              />
            </div>
          </div>
        </div>
      </div>
      <base-pagination-group
        v-model="prepareValues"
        :show-btn="false"
        :step-for-visible="10"
        :values="values"
        class="mt--30"
      />
    </base-card>
    <base-card v-else class="wallet items-in-column align-center">
      <div>Добавьте объявления в сравнение</div>
      <base-button :href="{ name: 'main' }" height="40" text="К объявлениям" />
    </base-card>
    <base-info-modal
      v-if="!!successDeleteMessage"
      :show-info-modal="!!successDeleteMessage"
      @close="successDeleteMessage = undefined"
    >
      <div>{{ successDeleteMessage }}</div>
    </base-info-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue';
import { HeaderType, ItemType } from '@/types/TableTypes';
import { useCompareStore } from '@/store/modules/compare';
import { useRouter } from 'vue-router';

const headers = computed((): HeaderType[] => {
  return [
    {
      text: 'Создан',
      value: 'createDate',
      align: 'center',
    },
    {
      text: 'Список',
      value: 'list',
      align: 'left',
      alignValue: 'left',
    },
    {
      text: '',
      value: 'eye',
      align: 'center',
      click: showCompare,
    },
    {
      text: '',
      value: 'delete',
      align: 'center',
      alignValue: 'center',
      click: deleteCompare,
    },
  ];
});
const values: Ref<ItemType[]> = ref([]);
const prepareValues: Ref<ItemType[]> = ref([]);
const isLoading: Ref<boolean> = ref(false);
const successDeleteMessage: Ref<string | undefined> = ref(undefined);
const router = useRouter();
const showCompare = async (item: ItemType) => {
  await router.push({ name: 'compare', query: { id: String(item['id'].value) } });
  useCompareStore().changeCurrentCompare(
    {
      id: +item['id'].value,
      name: String(item['list'].value),
    },
    [],
  );
};
const deleteCompare = async (item: ItemType) => {
  values.value = values.value.map(el => {
    if (el['id'].value === item['id'].value) {
      return { ...el, delete: { ...el.delete, isLoading: true } };
    }

    return el;
  });
  try {
    const res = await useCompareStore().deleteCompare(+item['id'].value);

    if (res) {
      successDeleteMessage.value = res.success;
      values.value = values.value.filter(el => {
        return el['id'].value !== item['id'].value;
      });
    }
  } catch {
    values.value = values.value.map(el => {
      if (el['id'].value === item['id'].value) {
        return { ...el, delete: { ...el.delete, isLoading: false } };
      }

      return el;
    });
  }
};

onMounted(async () => {
  isLoading.value = true;

  try {
    const comparisons = await useCompareStore().getComparisons();

    if (comparisons) {
      comparisons.forEach(el => {
        values.value.push({
          id: { value: el.id },
          createDate: { value: new Date(el.created_at).toLocaleString() },
          list: { value: el.name },
          eye: { value: '', icon: 'visibility' },
          delete: { value: '', icon: 'delete', isLoading: false },
        });
      });
      prepareValues.value = values.value;
    }
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped src="./CompareList.scss"></style>
