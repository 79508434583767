import { defineStore } from 'pinia';
import { store } from '@/store';
import {
  deleteChannelByIdApi,
  findChannelByLandIdApi,
  getChannelByIdApi,
  getChannelsApi,
  getCountChannelWithNewApi,
  readChannelByIdApi,
  sendMessageByLandIdApi,
  sendMessageInChannelApi,
} from '@/api/chat';
import { prepareUrl } from '@/mixins/Common/Common';
import { MessageMoreInfoType, MessagesFromChanelType } from '@/api/chat/types';
import { useUserStore } from '@/store/modules/user';
import { MiniMessengerType } from '@/types/MessangerTypes';
import { ResultCardType } from '@/types';
import { useAppStore } from '@/store/modules/app';
import { useOfferCardsStore } from '@/store/modules/offerCards';

type UseChatStoreType = {
  miniMessenger: {
    show: boolean;
    landId: number | undefined;
    isHidden: boolean;
    type: 10;
    more:
      | {
          house: MessageMoreInfoType;
          land: MessageMoreInfoType;
        }
      | undefined;
  };
  allChats: MessagesFromChanelType[];
  chatIdsWithNewMessage: number[];
  openChatIds: number[];
  chatInfo: { [key: string]: MessagesFromChanelType };
};

export const useChatStore = defineStore('chat', {
  state: (): UseChatStoreType => {
    return {
      miniMessenger: {
        show: false,
        landId: undefined,
        isHidden: false,
        type: 10,
        more: undefined,
      },
      allChats: [],
      chatIdsWithNewMessage: [],
      openChatIds: [],
      chatInfo: {},
    };
  },
  actions: {
    async openMiniMessenger(offerCard: ResultCardType | undefined, offerCardId?: number) {
      if (useUserStore().getIsLogin()) {
        let offerCardData = offerCard;

        if (!offerCard && offerCardId) {
          offerCardData = useOfferCardsStore().offerCards.find(
            el => el.id === offerCardId,
          );
          if (!offerCardData) {
            await useOfferCardsStore()
              .searchOfferCardById(offerCardId)
              .then(res => {
                offerCardData = res;
              });
          }
        }
        if (offerCardData) {
          this.miniMessenger = {
            show: true,
            landId: offerCardData.landId,
            type: 10,
            more: {
              house: {
                address: offerCardData.address,
                id: offerCardData.id,
                images: offerCardData.img || [],
                price: offerCardData.price,
              },
              land: {
                address: '',
                id: offerCardData.landId,
                images: offerCardData.landImgs || [],
                price: '',
              },
            },
            isHidden: false,
          };
        }
      } else {
        useAppStore().openAuthModal(true);
      }
    },
    closeMiniMessenger() {
      this.miniMessenger = {
        show: false,
        landId: undefined,
        more: undefined,
        type: 10,
        isHidden: true,
      };
    },
    toggleMiniMessenger(isHidden: boolean) {
      this.miniMessenger.isHidden = isHidden;
    },
    async findChannelByLandId(entity_id: number) {
      const res = await findChannelByLandIdApi(entity_id).catch(error => {
        return Promise.reject(error.response.data.errors.general[0]);
      });

      if (res) {
        return res.data.channelId;
      }
    },
    async sendMessageByLand(message: string, miniMessageInfo: MiniMessengerType) {
      if (miniMessageInfo.landId) {
        const res = await sendMessageByLandIdApi({
          message: message,
          entity_id: miniMessageInfo.landId,
        });

        if (res && miniMessageInfo.more?.house && miniMessageInfo.more?.land) {
          const userId = useUserStore().user?.id;

          if (userId) {
            this.allChats.push({
              created_at: res.data.messageCreatedAt,
              id: res.data.channelId,
              it_was_read: false,
              last_read: '',
              last_message: res.data.messageCreatedAt,
              unread_messages: 0,
              removable: false,
              messages: [
                {
                  user_id: userId,
                  message: message,
                  id: res.data.messageId,
                  it_was_read: false,
                  created_at: res.data.messageCreatedAt,
                },
              ],
              type: 10,
              more: miniMessageInfo.more,
            });
            this.chatInfo[res.data.channelId] = {
              created_at: res.data.messageCreatedAt,
              id: res.data.channelId,
              it_was_read: false,
              last_read: '',
              last_message: res.data.messageCreatedAt,
              removable: false,
              more: miniMessageInfo.more,
              type: 10,
              unread_messages: 0,
              messages: [
                {
                  user_id: userId,
                  message: message,
                  id: res.data.messageId,
                  created_at: res.data.messageCreatedAt,
                  it_was_read: false,
                },
              ],
            };
          }

          return res.data;
        }
      }
    },
    async sendMessageInChannel(message: string, channel_id: number) {
      const res = await sendMessageInChannelApi({
        message: message,
        channel_id: channel_id,
      });

      if (res) {
        this.allChats = this.allChats.map(el => {
          return el.id === channel_id
            ? { ...el, last_message: res.data.messageCreatedAt }
            : el;
        });
        const userId = useUserStore().user?.id;

        if (userId) {
          this.chatInfo[channel_id] = {
            ...this.chatInfo[channel_id],
            messages: [
              {
                ...this.chatInfo[channel_id].messages[0],
                user_id: userId,
                message: message,
                id: res.data.messageId,
                created_at: res.data.messageCreatedAt,
              },
              ...this.chatInfo[channel_id].messages,
            ],
          };
        }

        return res.data;
      }
    },
    async getAllChannels() {
      const res = await getChannelsApi();

      if (res) {
        const sortAllChats = (res: MessagesFromChanelType[]) => {
          this.allChats = res.sort((a, b) =>
            new Date(a.last_message) < new Date(b.last_message) ? 1 : -1,
          );
        };

        sortAllChats(
          res.data.channel?.map(el => {
            if (el.type === 10) {
              return {
                ...el,
                more: {
                  house: {
                    ...el.more.house,
                    images: el.more.house.images.map(img => {
                      return prepareUrl(img.path);
                    }),
                  },
                  land: {
                    ...el.more.land,
                    images: el.more.land.images.map(img => {
                      return prepareUrl(img.path);
                    }),
                  },
                },
              };
            } else {
              return el;
            }
          }),
        );
      }
    },
    async getChannelById(channelId: number) {
      const res = await getChannelByIdApi(channelId);

      if (res) {
        this.allChats = this.allChats.map(el => {
          if (el.id === channelId && !el.it_was_read) {
            this.readChannelById(channelId);

            return { ...el, it_was_read: true, unread_messages: 0 };
          }

          return el;
        });

        if (res.data.type === 10) {
          this.chatInfo[channelId] = {
            ...res.data,
            type: 10,
            more: {
              house: {
                ...res.data.more.house,
                images: res.data.more.house.images.map(img => {
                  return prepareUrl(img.path);
                }),
              },
              land: {
                ...res.data.more.land,
                images: res.data.more.land.images.map(img => {
                  return prepareUrl(img.path);
                }),
              },
            },
            it_was_read: true,
            unread_messages: 0,
          };
        } else {
          this.chatInfo[channelId] = {
            ...res.data,
            it_was_read: true,
            unread_messages: 0,
          };
        }
      }
    },
    async getCountChannelWithNew() {
      const res = await getCountChannelWithNewApi();

      if (res) {
        const arrOld = this.chatIdsWithNewMessage;
        const arrNew = res.data.withNewMessage;

        const arrDiff = (a: number[], b: number[]) => {
          let arr = b;

          for (let i = 0; i < a.length; i++) {
            arr = arr.filter(item => item !== a[i]);
          }

          return arr;
        };

        if (arrOld.length) {
          const diff = arrDiff(arrOld, arrNew);

          if (diff.length) {
            await this.getAllChannels();
          }
          diff.forEach(diffEl => {
            if (this.openChatIds.includes(diffEl)) {
              this.getChannelById(diffEl);
            }
          });
        }

        this.chatIdsWithNewMessage = arrNew;
      }
    },
    async readChannelById(channelId: number) {
      const res = await readChannelByIdApi({ channel_id: channelId });

      if (res) {
        const index = this.chatIdsWithNewMessage.findIndex(el => el === channelId);

        if (index >= 0) {
          this.chatIdsWithNewMessage.splice(index, 1);
        }

        return res.data;
      }
    },
    addOpenChatId(channelId: number) {
      this.openChatIds.push(channelId);
    },
    deleteOpenChatId(channelId: number) {
      const index = this.openChatIds.findIndex(el => el === channelId);

      if (index >= 0) {
        this.openChatIds.splice(index, 1);
      }
    },
    async deleteChatById(channelId: number) {
      const res = await deleteChannelByIdApi(channelId);

      if (res) {
        const index = this.allChats.findIndex(el => el.id === channelId);

        if (index >= 0) {
          this.allChats.splice(index, 1);
        }
        this.deleteOpenChatId(channelId);
        delete this.chatInfo[channelId];

        return res.data;
      }
    },
    clearOpenChatId() {
      this.openChatIds = [];
    },
  },
  getters: {},
});

export const useCheckResultStoreWithOut = () => {
  return useChatStore(store);
};
