<template>
  <div class="items-in-line gap--9">
    <div v-for="icon in icons" :key="icon.id" class="relative">
      <base-button :href="icon.href" icon style-type="border" @click="icon.clickHandler">
        <template #icon>
          <img
            :alt="icon.id"
            :class="{ 'icon-bell': icon.id === 'bell' && icon.count }"
            :src="icon.icon"
          />
        </template>
        <template #iconActive>
          <img :alt="icon.id" :src="icon.activeIcon" />
        </template>
      </base-button>
      <span v-if="icon.count" class="icon-count">{{ icon.count }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref } from 'vue';
import { useChatStore } from '@/store/modules/chat';
import { useUserStore } from '@/store/modules/user';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { useAppStore } from '@/store/modules/app';
import { useOfferCardsStore } from '@/store/modules/offerCards';

type IconType = {
  id: string;
  icon: string;
  activeIcon: string;
  href?: RouteLocationRaw;
  clickHandler?: () => void;
  count?: number;
};

let timer: Ref<number | undefined> = ref(undefined);
const startTimer = () => {
  timer.value = setInterval(() => {
    useChatStore().getCountChannelWithNew();
    useUserStore().getCountNotifications();
  }, 30000);
};
const stopTimer = () => {
  clearInterval(timer.value);
};

const icons = computed((): IconType[] => {
  return [
    {
      id: 'search',
      icon: require('@/assets/img/header/icons/icon-search.png'),
      activeIcon: require('@/assets/img/header/icons/icon-search-active.png'),
      clickHandler: () => {
        router.push({ name: 'main' }).then(() => {
          useOfferCardsStore().toggleShowFilterModal(true);
        });
      },
    },
    {
      id: 'compare',
      icon: require('@/assets/img/header/icons/icon-menu.png'),
      activeIcon: require('@/assets/img/header/icons/icon-menu-active.png'),
      href: { name: 'compare' },
      count: useUserStore().user?.comparisons,
    },
    {
      id: 'like',
      icon: require('@/assets/img/header/icons/icon-like.png'),
      activeIcon: require('@/assets/img/header/icons/icon-like-active.png'),
      clickHandler: () =>
        checkUserLoginHandler({ name: 'profile', params: { id: 'favorite' } }),
      count: useUserStore().user?.favorite_ids.length,
    },
    {
      id: 'message',
      icon: require('@/assets/img/header/icons/icon-message.png'),
      activeIcon: require('@/assets/img/header/icons/icon-message-active.png'),
      clickHandler: () =>
        checkUserLoginHandler({ name: 'profile', params: { id: 'chat' } }),
      count: useChatStore().chatIdsWithNewMessage.length,
    },
    {
      id: 'bell',
      icon: require('@/assets/img/header/icons/icon-bell.png'),
      activeIcon: require('@/assets/img/header/icons/icon-bell-active.png'),
      clickHandler: () =>
        checkUserLoginHandler({ name: 'profile', params: { id: 'notifications' } }),
      count: useUserStore().user?.notificationUser.open,
    },
  ];
});
const router = useRouter();
const checkUserLoginHandler = (href: {
  name: string;
  params?: { id: string };
  hash?: string;
}): void => {
  if (useUserStore().getIsLogin()) {
    router.push(href);
  } else {
    useAppStore().openAuthModal(true);
  }
};

onMounted(async () => {
  if (useUserStore().getIsLogin()) {
    await useChatStore().getCountChannelWithNew();
    await useUserStore().getCountNotifications();
    startTimer();
  }
});

onUnmounted(() => {
  stopTimer();
});
</script>

<style lang="scss" scoped src="./ActionsBlock.scss"></style>
